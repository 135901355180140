<template>
    <div class="DeptCreate">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" label-width="100px" :model="form" @submit.native.prevent size="small">
                <el-form-item label="名称" prop="name">
                    <el-input v-model.trim="form.name" />
                </el-form-item>
                <el-row>
                    <el-form-item
                        label="营业执照名称"
                        prop="deptLicenseName"
                        v-if="form.type === deptTypeEnum.STORE && form.shopType === 0"
                    >
                        <el-input v-model.trim="form.deptLicenseName" />
                    </el-form-item>
                </el-row>

                <el-form-item label="类型" prop="type">
                    <el-radio-group v-model="form.type" v-if="form.type !== 3">
                        <el-radio :label="form.type">{{ deptTypeEnum.properties[form.type].desc }}</el-radio>
                    </el-radio-group>
                    <el-radio-group v-model="form.shopType" v-if="form.type === 3">
                        <el-radio :label="0">{{ deptTypeEnum.properties[form.type].desc }}</el-radio>
                        <el-radio :label="1">仓库</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="所属机构组:" style="white-space: nowrap">
                    {{ deptGroupName }}
                </el-form-item>
                <el-form-item label="行政区域" prop="metaArea">
                    <ef-cascade-area @ok="handleSelectArea" v-model="form.metaArea" />
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                    <el-input v-model.trim="form.address" />
                </el-form-item>
                <el-form-item
                    v-if="deptTypeEnum.properties[form.type].desc === '门店' && form.shopType === 0"
                    label="免费期限"
                    prop="freeYears"
                >
                    <el-input v-model.trim="form.freeYears" style="width: 50px" @input="valueCheck" />
                    <span> 年</span>
                </el-form-item>
                <el-form-item
                    label="合同日期"
                    prop="createTime"
                    v-if="deptTypeEnum.properties[form.type].desc === '门店' && form.shopType === 0"
                >
                    <el-date-picker
                        value-format="timestamp"
                        v-model="form.contractTime"
                        placeholder="选择日期"
                        :picker-options="pickerOptions"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                    <el-button @click="handleReset('form')" size="small">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import EfCascadeArea from 'components/EfCascadeArea';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'DeptCreate',
    components: { EfCascadeArea },
    data() {
        return {
            url: {
                applyCode: '/system/dept/code',
                create: '/system/dept/create',
            },
            pickerOptions: {
                disabledDate: (time) => {
                    //不能大于当前日期
                    return time.getTime() > Date.now();
                },
            },
            form: {
                contractTime: '',
                code: '',
                name: '',
                deptLicenseName: '',
                shopType: 0,
                type: 3,
                provinceCode: '',
                cityCode: '',
                districtCode: '',
                address: '',
                metaArea: [],
                deptGroupCode: '',
                freeYears: '',
            },
            deptGroupName: '',
            deptTypeEnum: DeptTypeEnum,
        };
    },
    activated() {
        if (this.$route.params.reloadAllFlag) {
            if (this.$route.params.deptGroupCode !== this.form.deptGroupCode) {
                //deptGroupCode不同时 属于切换部门从新进入到新建页面 需要清空输入内容
                Object.assign(this.$data, this.$options.data());
            }
            //接收更新的信息
            this.deptGroupName = this.$route.params.deptGroupName;
            this.form.type = this.$route.params.deptGroupType;
            this.form.deptGroupCode = this.$route.params.deptGroupCode;
            fetch(this.$proxyApiHostUrlSuffix + this.url.applyCode)
                .then((a) => a.text())
                .then((a) => {
                    this.form.code = a;
                });
        }
    },
    computed: {
        areas() {
            return [this.form.provinceCode, this.form.cityCode, this.form.districtCode];
        },
    },
    methods: {
        handleSelectArea(areas) {
            this.form.provinceCode = areas[0];
            this.form.cityCode = areas[1];
            this.form.districtCode = areas[2];
        },
        handleSave() {
            const form = JSON.parse(JSON.stringify(this.form));
            if (!form.name) {
                this.$alert('请填写名称', '提示', { type: 'warning' });
                return;
            }
            if (
                this.deptTypeEnum.properties[form.type].desc === '门店' &&
                !form.deptLicenseName &&
                form.shopType === 0
            ) {
                this.$alert('请填写营业执照名称', '提示', { type: 'warning' });
                return;
            }
            if (!form.address) {
                this.$alert('请填写详细地址', '提示', { type: 'warning' });
                return;
            }
            if (this.deptTypeEnum.properties[form.type].desc === '门店' && !form.freeYears && form.shopType === 0) {
                this.$alert('请填写免费期限', '提示', { type: 'warning' });
                return;
            }
            if (this.deptTypeEnum.properties[form.type].desc === '门店' && !form.contractTime && form.shopType === 0) {
                this.$alert('请填写合同日期', '提示', { type: 'warning' });
                return;
            }
            if (!this.form.deptGroupCode) {
                this.$alert('当前未选定机构组，请关闭页面后重试', '提示', { type: 'warning' });
                return;
            }
            delete form.type;
            UrlUtils.PostRemote(this, this.url.create, this.form, null, () => {
                this.goBackAndReload();
                this.$message.success('新建成功');
            });
        },
        handleReset(formName) {
            const deptGroupType = this.form.type;
            this.$refs[formName].resetFields();
            this.form.contractTime = '';
            this.form.type = deptGroupType;
        },
        valueCheck(e) {
            e = e.replace(/[^\d]/g, '');
            this.$nextTick(() => {
                this.$set(this.form, 'freeYears', e);
                if (e > 99) {
                    this.$set(this.form, 'freeYears', 99);
                }
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
