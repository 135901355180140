<template>
    <el-cascader :props="areas" ref="cas" @change="ok" v-model="vm" />
</template>

<script>
import { proxyApiHostUrlSuffix } from 'request/http';

export default {
    name: 'EfCascadeArea',
    model: {
        prop: 'vm',
        event: 'ok',
    },
    props: ['value', 'vm'],
    data() {
        return {
            areas: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const areasUrl = proxyApiHostUrlSuffix + '/system/area/list';
                    const { level } = node;
                    let _url = areasUrl + '?level=' + (level + 1);
                    if (level) {
                        _url += '&parentCode=' + node.data.value;
                    }
                    fetch(_url)
                        .then((a) => a.json())
                        .then((a) => {
                            resolve(
                                a.data.map((area) => {
                                    return {
                                        value: area.code,
                                        label: area.name,
                                        leaf: level == 2,
                                    };
                                })
                            );
                        });
                },
            },
        };
    },
    methods: {
        ok(data) {
            this.$emit('ok', data);
        },
    },
};
</script>
